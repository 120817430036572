import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Hamburger } from '../Hamburger';
import { SidebarMenuItems } from '../../types/sidebarMenuItems';
import { Avatar } from '../Avatar';
import SidebarItem from '../SidebarItem/SidebarItem';
import './sidebar.scss';
import useSidebarComponentState from './useSidebarComponentState';

interface SidebarProps {
  cursos?: boolean;
  webinars?: boolean;
  conectas?: boolean;
  gestaoSalaAula?: boolean;
  materiais?: boolean;
  mentorias?: boolean;
  instrutor?: boolean;
  profile?: boolean;
}

export const Sidebar = ({
  instrutor,
  materiais,
  cursos,
  webinars,
  conectas,
  gestaoSalaAula,
  profile,
}: SidebarProps) => {
  const {
    logout,
    hamburgerOpen,
    menuBurger,
    toggleHamburger,
    menuIconClick,
    menuIsOpen,
    usuario,
    isPermissionamentoMaterial,
    isPermissionamentoGestaoSala,
    isPermissionamentoConecta,
    iconButton,
    happyLogo,
  } = useSidebarComponentState();

  const hamburgerComponent = {
    isOpen: hamburgerOpen,
    onClick: toggleHamburger,
    menuHamburguerClass: menuBurger,
    isPermissionamentoConecta: isPermissionamentoConecta,
    isPermissionamentoMaterial: isPermissionamentoMaterial,
    isPermissionamentoGestaoSala: isPermissionamentoGestaoSala,
  };

  const menuItems: SidebarMenuItems[] = [
    {
      active: instrutor,
      link: '/instrutor',
      label: 'Home',
      icon: <Unicons.UilHome />,
      userCanView: true,
    },
    {
      active: cursos,
      link: '/cursos',
      label: 'Cursos',
      icon: <Unicons.UilGraduationCap />,
      userCanView: true,
    },
    {
      active: webinars,
      link: '/webinars/all',
      label: 'Webinars',
      icon: <Unicons.UilBookmark />,
      userCanView: true,
    },
    {
      active: conectas,
      link: '/conectas/all',
      label: 'Conecta',
      icon: <Unicons.UilPlug />,
      userCanView: isPermissionamentoConecta,
    },
    {
      active: materiais,
      link: '/materiais',
      label: 'Materiais de apoio',
      icon: <Unicons.UilBookReader />,
      userCanView: isPermissionamentoMaterial,
    },
    {
      active: gestaoSalaAula,
      link: '/turmas',
      label: 'Gestão de sala de aula',
      icon: <Unicons.UilGrid />,
      userCanView: isPermissionamentoGestaoSala,
    },
  ];

  return (
    <>
      <Hamburger {...hamburgerComponent} />
      <aside className="sidebar-wrapper">
        <div className="sidebar-wrapper__logo-container">
          <img
            alt="Happy Logo images"
            src={happyLogo}
            onClick={menuIconClick}
          />
          <Button
            onClick={menuIconClick}
            icon={iconButton}
            className="logo-container__button-menu"
          />
        </div>
        <div className="sidebar-wrapper__menu-items-list">
          {menuItems.map(
            ({ userCanView, active, link, icon, label }, index) => (
              <React.Fragment key={index}>
                {userCanView && (
                  <SidebarItem
                    menuIsOpen={menuIsOpen}
                    label={label}
                    active={active}
                    link={link}
                    icon={icon}
                  />
                )}
              </React.Fragment>
            ),
          )}
        </div>
        <div className="sidebar-wrapper__user-logout-list">
          <Link
            to="/usuarios/profile"
            className={`user-logout-list__avatar-user ${
              profile && 'user-logout-list__avatar-user-active'
            }`}
          >
            <Avatar base64={usuario?.arquivo} flHeader={true} size={30} />
            {menuIsOpen && (
              <div className="avatar-user">
                <h6>{usuario?.usuario}</h6>
                <p>{usuario?.perfil}</p>
              </div>
            )}
          </Link>
          <SidebarItem
            menuIsOpen={menuIsOpen}
            label={'Sair'}
            onClick={logout}
            icon={<Unicons.UilSignout />}
          />
        </div>
      </aside>
    </>
  );
};
