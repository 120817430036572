import type { IndicatorCardType } from '../../../../types/indicator-card';
import * as Unicons from '@iconscout/react-unicons';
import aulaImage from '../../../../assets/img/dashboard/aula.svg';
import flagImage from '../../../../assets/img/dashboard/flag.svg';
import { useUserKpiStore } from '../../../../store/home/user-kpi.store';

type FindOutWhatCardTypeIs = {
  [key in IndicatorCardType]: {
    title: string;
    value: string;
    image?: string;
    icon?: JSX.Element;
    color: string;
  };
};

interface UseIndicatorCardProps {
  cardType: IndicatorCardType;
}
export default function useIndicatorCard({ cardType }: UseIndicatorCardProps) {
  const { userKpi } = useUserKpiStore();

  const classesAttend = (userKpi.quantidadeAulasAssistidas || 0).toString();
  const ongoingCourses = (userKpi.quantidadeCursosAndamento || 0).toString();
  const completedCourses = (
    userKpi.quantidadeCursosFinalizados || 0
  ).toString();
  const completedTrails = (userKpi.quantidadeTrilhasConcluidas || 0).toString();

  const findOutWhatCardTypeIs: FindOutWhatCardTypeIs = {
    aulasAssistidas: {
      title: 'AULAS ASSISTIDAS',
      value: classesAttend,
      image: aulaImage,
      color: 'var(--happy-code)',
    },
    cursosEmAndamento: {
      title: 'CURSOS EM ANDAMENTO',
      value: ongoingCourses,
      icon: <Unicons.UilClock color="var(--white-color)" />,
      color: 'var(--happy-money)',
    },
    cursosConcluidos: {
      title: 'CURSOS FINALIZADOS',
      value: completedCourses,
      icon: <Unicons.UilPostcard color="var(--white-color)" />,
      color: 'var(--happy-speech)',
    },
    trilhasConcluidas: {
      title: 'Trilhas Concluídas',
      value: completedTrails,
      image: flagImage,
      color: 'var(--azul-primario)',
    },
  };

  const getCardType = findOutWhatCardTypeIs[cardType];

  return {
    getCardType,
  };
}
