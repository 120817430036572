import { Button } from 'antd';
import './icon-button.styles.scss';

interface IconButtonProps {
  onClick?: () => void;
  icon: React.ElementType;
}
export default function IconButton({ onClick, icon: Icon }: IconButtonProps) {
  return (
    <Button onClick={onClick} className="icon-button">
      <Icon size="24" color={'var(--primary-color)'} />
    </Button>
  );
}
