import { useParams } from 'react-router-dom';
import { useTurmaContext } from '../../context/TurmaContext';
import {
  IAulaCustomDTO,
  IMatriculaFullDTO,
  ITurmaFullDTO,
} from '../../models/happy-code-api.model';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTurmaService } from '../../services/turma.service';
import { useGlobalContext } from '../../context/GlobalContext';
import { PageableResponse } from '../../models/response.model';
import { Predicate } from '../../models/predicate.model';
import { useAulaService } from '../../services/aula.service';
import { useMatriculaService } from '../../services/matricula.service';

export default function useTurmaDetalhe() {
  const { id } = useParams<{ id: any }>();

  const { removeTurma } = useTurmaContext();
  const [turma, setTurma] = useState<ITurmaFullDTO>();
  const { findFullById } = useTurmaService();

  const [listAula, setListAula] = useState<PageableResponse<IAulaCustomDTO>>();
  const [predicateAula] = useState<Predicate>(new Predicate());
  const { findCustom } = useAulaService();

  const [listMatricula, setListMatricula] =
    useState<PageableResponse<IMatriculaFullDTO>>();
  const [matriculaPredicate] = useState<Predicate>(new Predicate());
  const { findFull } = useMatriculaService();

  const [page, setPage] = useState<number>(0);

  const { setIsGlobalLoading } = useGlobalContext();

  const findTurma = useCallback(
    async () => await findFullById({ id }).then((res) => setTurma(res.data)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const findMatricula = useCallback(async () => {
    matriculaPredicate.addOption('turmaId', id);
    matriculaPredicate.sort = 'asc';
    matriculaPredicate.addSort('statusMatricula');

    await findFull(matriculaPredicate).then((res) =>
      setListMatricula(res.data),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findAula = useCallback(async () => {
    predicateAula.addOption('turma.id', id);

    await findCustom(predicateAula).then((response) => {
      setListAula(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predicateAula]);

  const executaFindFunctions = useCallback(async () => {
    setIsGlobalLoading(true);

    await Promise.all([findTurma(), findMatricula()]);

    findAula();

    setIsGlobalLoading(false);
  }, [findAula, findMatricula, findTurma, setIsGlobalLoading]);

  useEffect(() => {
    const controller = new AbortController();

    removeTurma();
    executaFindFunctions();

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executaFindFunctions]);

  const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();

    setPage(page);
    predicateAula.setPage(page);
    setIsGlobalLoading(true);

    findAula().finally(() => setIsGlobalLoading(false));
  };

  const onPageChangeMatricula = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();

    matriculaPredicate.setPage(page);
    setIsGlobalLoading(true);
    findMatricula().finally(() => setIsGlobalLoading(false));
  };

  return {
    turma,
    listAula,
    listMatricula,
    page,
    findMatricula,
    onPageChange,
    onPageChangeMatricula,
  };
}
