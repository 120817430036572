import { useCallback, useState } from 'react';
import { useUsuarioCursoService } from '../../../../services/usuario-curso.service';
import { useParams } from 'react-router-dom';

export default function useFindUserProgress() {
  const { idCurso } = useParams<{
    idTrilha: string;
    idCurso: string;
  }>();

  const [userProgress, setUserProgress] = useState<number>(0);

  const { getConclusao } = useUsuarioCursoService();

  const findUserProgress = useCallback(async () => {
    const parseIntIdCurso = parseInt(idCurso);

    await getConclusao(parseIntIdCurso).then(({ data }) =>
      setUserProgress(data),
    );
  }, [idCurso]);

  return {
    findUserProgress,
    userProgress,
  };
}
