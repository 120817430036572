import dayjs from 'dayjs';
import ImgYoutube from '../../../../assets/img/box-curso/youtube.svg';
import { Tags } from '../../../../components/Tags';
import {
  IAulaFullDTO,
  IMatriculaFullDTO,
} from '../../../../models/happy-code-api.model';
import './box-lancamento-aula.css';
import { SentimentSatisfiedAltOutlined } from '@mui/icons-material';

interface BoxLancamentoAulaProps {
  aula: IAulaFullDTO;
  listMatricula: IMatriculaFullDTO[];
}
export default function BoxLancamentoAula({
  aula,
  listMatricula,
}: BoxLancamentoAulaProps) {
  return (
    <div className="box-lancamento-aula">
      <div className="avatar-turma">
        <img src={ImgYoutube} alt="Imagem avatar da turma" />
        <Tags tipo={aula?.turma.descricao} />
      </div>
      <div className="data-aula">
        <h5 className="data-aula__titulo">{aula?.turma?.descricao}</h5>
        <h4 className="data-aula__data">
          {dayjs(aula?.data).format('DD/MM/YYYY')}
        </h4>
        <div className="data-aula__info-icones">
          <SentimentSatisfiedAltOutlined />
          <p>{listMatricula?.length} alunos ativos</p>
        </div>
      </div>
    </div>
  );
}
