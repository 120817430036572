import useQuantidadeAulaRealizadas from '../../hooks/useQuantidadeAulaRealizadas';
import useTurmaDetalhe from '../../useTurmaDetalhe';

export default function usePorcentagem() {
  const { turma } = useTurmaDetalhe();
  const quantidadeAulasRealizadas = useQuantidadeAulaRealizadas();

  const getPorcentagem = () => {
    if (!turma || !turma.aulas) return 0;

    const porcentagem =
      (quantidadeAulasRealizadas() / turma.aulas.length) * 100;

    return parseFloat(porcentagem.toFixed(2));
  };

  return getPorcentagem;
}
