import { Button } from 'antd';
import { ChangeEvent } from 'react';
import { PageableResponse } from '../../../../models/response.model';
import { IMatriculaFullDTO } from '../../../../models/happy-code-api.model';
import ModalLancarNota from '../../../../components/ModalProva';
import StatusMatricula from '../StatusMatricula';
import { Pagination } from '@material-ui/lab';
import './avaliacoes-table.css';
import useAvaliacoesTable from './useAvaliacoesTable';

interface AvaliacoesTableProps {
  listMatricula: PageableResponse<IMatriculaFullDTO>;
  onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
  findMatricula: () => Promise<void>;
}

export default function AvaliacoesTable({
  listMatricula,
  onPageChange,
  findMatricula,
}: AvaliacoesTableProps) {
  const {
    lancamentoNota,
    provaSelected,
    idMatriculaSelected,
    handleLancarNota,
    onCloseModal,
  } = useAvaliacoesTable({ findMatricula });

  return (
    <>
      <div className="avaliacoes-table-wrapper">
        <div className="avaliacoes-table">
          <div className="avaliacoes-table__header">
            <div className="header__cell" style={{ width: '30%' }}>
              <span>NOME DO ALUNO</span>
            </div>
            <div className="header__cell" style={{ width: '20%' }}>
              <span>Média Term 1 (0-50)</span>
            </div>
            <div className="header__cell" style={{ width: '20%' }}>
              <span>Média Term 2 (0-50)</span>
            </div>
            <div className="header__cell" style={{ width: '20%' }}>
              <span>Média Final (0-100)</span>
            </div>
            <div className="header__cell" style={{ width: '20%' }}>
              <span>Lançar nota</span>
            </div>
          </div>
          {listMatricula?.content.length === 0 && 'Nenhum Aluno'}
          {listMatricula?.content.map(
            ({
              id,
              alunoPessoaNome,
              statusMatricula: { codigo, descricao },
              matriculaProva,
            }) => (
              <div key={id} className="avaliacoes-table__row">
                <div className="row__aluno-cell" style={{ width: '30%' }}>
                  <p className="aluno-cell__aluno-nome">{alunoPessoaNome}</p>
                  <StatusMatricula
                    codigoStatusMatricula={codigo}
                    descricaoStatusMatricula={descricao}
                  />
                </div>
                <div className="row__cell" id="alunos" style={{ width: '20%' }}>
                  <p>{matriculaProva?.primeiraNota ?? 0}</p>
                </div>
                <div className="row__cell" style={{ width: '20%' }}>
                  <p>{matriculaProva?.segundaNota ?? 0}</p>
                </div>
                <div className="row__cell" style={{ width: '20%' }}>
                  <p>{matriculaProva?.finalNota ?? 0}</p>
                </div>
                <div className="row__cell" style={{ width: '20%' }}>
                  <Button
                    onClick={() => handleLancarNota(matriculaProva, id)}
                    className="button-primary"
                  >
                    Lançar nota
                  </Button>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="paginacao">
          <Pagination
            onChange={(event, page) => onPageChange(event, page - 1)}
            page={listMatricula ? listMatricula.pageable.pageNumber + 1 : 0}
            count={listMatricula ? listMatricula.totalPages : 0}
          />
        </div>
      </div>
      <ModalLancarNota
        open={lancamentoNota}
        onClose={onCloseModal}
        prova={provaSelected}
        idMatricula={idMatriculaSelected}
      />
    </>
  );
}
