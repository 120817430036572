import { useState } from 'react';
import { IMatriculaProvaCleanDTO } from '../../../../models/happy-code-api.model';

interface useAvaliacoesTableProps {
  findMatricula: () => Promise<void>;
}

export default function useAvaliacoesTable({
  findMatricula,
}: useAvaliacoesTableProps) {
  const [lancamentoNota, setLancamentoNota] = useState<boolean>();
  const [provaSelected, setProvaSelected] = useState<IMatriculaProvaCleanDTO>();
  const [idMatriculaSelected, setIdMatriculaSelected] = useState<number>(0);

  const handleLancarNota = (
    matriculaProva: IMatriculaProvaCleanDTO,
    idMatricula: number,
  ) => {
    setLancamentoNota(true);
    setProvaSelected(matriculaProva);
    setIdMatriculaSelected(idMatricula);
  };

  const onCloseModal = async () => {
    setLancamentoNota(false);
    await findMatricula();
  };

  return {
    lancamentoNota,
    provaSelected,
    idMatriculaSelected,
    handleLancarNota,
    onCloseModal,
  };
}
