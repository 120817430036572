import { Button } from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import { isAfterDate } from '../../../../util/format';
import { useNavigate } from 'react-router-dom';
import { Tags } from '../../../../components/Tags';
import { ChangeEvent } from 'react';
import { PageableResponse } from '../../../../models/response.model';
import { IAulaCustomDTO } from '../../../../models/happy-code-api.model';
import './aulas-table.css';
import dayjs from 'dayjs';

interface AulasTableProps {
  listAula: PageableResponse<IAulaCustomDTO>;
  onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
  page: number;
}

export default function AulasTable({
  listAula,
  onPageChange,
  page,
}: AulasTableProps) {
  const navigate = useNavigate();

  return (
    <div className="aulas-table-wrapper">
      <div className="aulas-table">
        <div className="aulas-table__header">
          <div className="header__cell" style={{ width: '15%' }}>
            <span>NOME</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>DATA</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>STATUS</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>
              ALUNOS <br /> PRESENTES
            </span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>
              ALUNOS <br /> FALTOSOS
            </span>
          </div>{' '}
          <div className="header__cell" style={{ width: '15%' }}>
            <span>Lançar Aula</span>
          </div>
        </div>
        {listAula && listAula.content.length === 0 && 'Nenhuma aula'}
        {listAula &&
          listAula.content.map((aula, index) => (
            <div key={index} className="aula-table__row">
              <div className="row__cell" style={{ width: '15%' }}>
                <p>{'Aula ' + (page * 10 + (index + 1))}</p>
              </div>
              <div className="row__cell" style={{ width: '15%' }}>
                <p>{dayjs(aula.data).format('DD/MM/YYYY')}</p>
              </div>
              <div className="row__cell" style={{ width: '15%' }}>
                <Tags tipo={aula.possuiLancamento ? 'lancado' : 'pendente'} />
              </div>
              <div className="row__cell" style={{ width: '15%' }}>
                <p>{aula.alunosPresentes}</p>
              </div>
              <div className="row__cell" style={{ width: '15%' }}>
                <p>{aula.alunosFaltosos}</p>
              </div>
              <div
                className="row__cell"
                style={{ width: '15%', position: 'relative' }}
              >
                {(isAfterDate(aula.data) || aula.possuiLancamento) && (
                  <Button
                    onClick={() =>
                      navigate(`/turmas/professor/lancamento-aula/${aula.id}`)
                    }
                    className="button-primary"
                  >
                    {aula.possuiLancamento ? 'Editar' : 'Lançar Aula'}
                  </Button>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="paginacao">
        <Pagination
          onChange={(event, page) => onPageChange(event, page - 1)}
          page={listAula ? listAula.pageable.pageNumber + 1 : 0}
          count={listAula ? listAula.totalPages : 0}
        />
      </div>
    </div>
  );
}
