import { ITurmaDTO } from '../../../../models/happy-code-api.model';
import './status-turma.css';
import useStatusTurma from './useStatusTurma';

interface StatusTurmaProps {
  turma: ITurmaDTO;
}
export default function StatusTurma({ turma }: StatusTurmaProps) {
  const { dotColor, statusText } = useStatusTurma({ turma });

  return (
    <div className="status-turma">
      <div className={dotColor}></div>
      <p className={statusText === 'Em andamento' ? 'ativo' : 'inativo'}>
        {statusText}
      </p>
    </div>
  );
}
