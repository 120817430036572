import { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { IUsuarioCursoDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useUsuarioCursoService } from '../../services/usuario-curso.service';

const useInstrutor = () => {
  const { usuario, setIsGlobalLoading } = useGlobalContext();

  const limitOfCourses = 4;
  const predicate = new Predicate(limitOfCourses);

  const usuarioCursoService = useUsuarioCursoService();

  const [myCourses, setMyCourses] = useState<IUsuarioCursoDTO[]>([]);

  const getMyCourses = useCallback(() => {
    if (!usuario?.id) return;

    setIsGlobalLoading(true);

    predicate.addOption('usuario.id', usuario.id);

    usuarioCursoService
      .find(predicate)
      .then(({ data }) => setMyCourses(data.content))
      .catch((error) => console.error(error))
      .finally(() => setIsGlobalLoading(false));
  }, [setIsGlobalLoading, usuario.id]);

  useEffect(() => {
    const controller = new AbortController();

    getMyCourses();

    return () => controller.abort();
  }, [getMyCourses]);

  const iHaveCourses = myCourses.length > 0;

  const iHaventCourses = !myCourses || myCourses.length <= 0;

  return {
    myCourses,
    iHaveCourses,
    iHaventCourses,
  };
};

export default useInstrutor;
