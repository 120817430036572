import * as Unicons from '@iconscout/react-unicons';
import { Checkbox, Drawer, Form } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import People from '../../assets/img/login/img-people.png';
import LogoGrande from '../../assets/img/login/logo.svg';
import Logo from '../../assets/img/sidebar/logo-grande.svg';
import PasswordEye from '../../components/PasswordEye';
import useLoginComponentState from './selectors/login.component.state';

interface TInputs {
  user: string;
  password: string;
}

export const Login = () => {
  const [eyePassword, setEyePassword] = useState<boolean>(false);
  const { state, onRealizarLogin, unidades, onClickUnidade, onToggle } =
    useLoginComponentState();
  const { register, handleSubmit } = useForm<TInputs>();

  const onSubmit = async (data: TInputs) => {
    onRealizarLogin(data);
  };

  const optionsUnidade = unidades.map((unidade) => {
    return (
      <div
        onClick={() => onClickUnidade(unidade)}
        className="box-login"
        key={unidade.id}
      >
        <Unicons.UilBuilding size="30" color="#92A9CB" />
        <p>{unidade.descricao}</p>
      </div>
    );
  });

  return (
    <>
      <div className="container-login">
        <img src={Logo} alt={'logo'} className="logo-login" />
        <img src={LogoGrande} alt={'logo'} className="img-layout" />
        <div className="formulario-box">
          <form onSubmit={handleSubmit(onSubmit)} style={{ zIndex: 3 }}>
            <div className="formulario">
              <h3>Bem-vindo,</h3>
              <p>Informe seu e-mail e senha para realizar o login</p>
              <div className="input">
                <Unicons.UilEnvelopeAlt
                  size="20"
                  color="#6A7C96"
                  className="iconForm"
                />
                <Form.Item name="email" noStyle={true}>
                  <input
                    {...register('user', { required: true })}
                    type="text"
                    id="username"
                    placeholder="Email"
                  />
                </Form.Item>
              </div>
              <div className="input">
                <Unicons.UilLock
                  size="20"
                  color="#6A7C96"
                  className="iconForm"
                />

                <input
                  {...register('password', { required: true })}
                  type={eyePassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Senha"
                />
                <PasswordEye
                  eventEye={eyePassword}
                  setEventEye={setEyePassword}
                />
              </div>
              <div className="lembrete">
                <Checkbox>
                  <span style={{ color: 'white' }}>Lembre-me</span>
                </Checkbox>
                <Link to="/senha">
                  <span id="green-happy">Esqueceu sua senha</span>
                </Link>
              </div>
              <button
                type="submit"
                id="entrar"
                className="button-second"
                style={{ width: '100%', marginTop: 20 }}
              >
                <span>
                  {state?.flRealizandoLogin ? 'Aguarde...' : 'Entrar'}
                </span>
              </button>
              {/* <span className="conta">Ainda não tem uma conta? </span>
              <span className="cadastro">Cadastre-se agora</span> */}
            </div>
          </form>
        </div>
        <div className="container-blue"></div>
        <img src={People} alt="" className="img-people" />

        <Drawer
          title="Selecione uma Franquia para Logar"
          onClose={onToggle}
          open={state.open}
          placement="top"
        >
          <div className="boxes-login">
            <div style={{ display: 'flex', position: 'absolute' }}>
              {optionsUnidade}
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
