import { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { ITurmaFullDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { useTurmaService } from '../../../../services/turma.service';
import { usePessoaIdStore } from '../../../../store/global/pessoa-id.store';

export default function useClassManagement() {
  const { usuario, unidade } = useGlobalContext();

  const accessB2b = usuario?.acessoB2b;
  const accessB2c = usuario?.acessoB2c;

  const userIsInstructor = accessB2b || accessB2c;

  const { pessoaId } = usePessoaIdStore();

  const predicate = new Predicate();

  const turmaService = useTurmaService();

  const [outgoingClass, setOutgoingClass] = useState<ITurmaFullDTO[]>([]);

  const fetchOutgoingClass = useCallback(async () => {
    if (!pessoaId) return;

    predicate.addOption('unidadePessoa.pessoa.id', pessoaId);

    if (unidade.id) predicate.addOption('unidade.id', unidade?.id);

    predicate.addOption('ativo', true);
    predicate.size = 4;

    await turmaService
      .findFull(predicate)
      .then(({ data }) => setOutgoingClass(data.content));
  }, [pessoaId, unidade.id]);

  useEffect(() => {
    const controller = new AbortController();

    fetchOutgoingClass();

    return () => controller.abort();
  }, [fetchOutgoingClass]);

  const hasOutgoingClass = outgoingClass.length > 0;

  return {
    userIsInstructor,
    hasOutgoingClass,
    outgoingClass,
  };
}
