import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { Sidebar } from '../../components/Sidebar';
import '../../global.css';
import './style.css';
import { useNavigate } from 'react-router-dom';
import useLancamentoAula from './useLancamentoAula';
import BoxLancamentoAula from './components/BoxLancamentoAula';
import MarcarPresencaAlunosTable from './components/MarcarPresencaAlunosTable';

export const LancamentoAula: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    aula,
    listMatricula,
    isView,
    onChangeFrequencia,
    onChangeFrequenciaTarefa,
    onChangeFrequenciaReforco,
    registrarLancamento,
    getFrequenciaValue,
    getFrequenciaValueTarefa,
    getFrequenciaValueReforco,
  } = useLancamentoAula();

  return (
    <div className="container">
      <Sidebar instrutor={true} />
      <div className="franquia" id="aluno-detalhe-row">
        <div
          className="franquia-row"
          style={{ marginTop: 70 }}
          id="row-aluno-box"
        >
          <div className="lancamento-aulas__head-botoes">
            <div className="head-botoes__voltar-titulo">
              <Button
                className="button-second"
                onClick={() => navigate(-1)}
                icon={<ArrowLeftOutlined />}
              >
                Voltar
              </Button>
              <h5 className="head-botoes__titulo">Lançamento de Aula</h5>
            </div>
            <Button
              onClick={() => registrarLancamento()}
              className="button-primary"
            >
              Registrar Lançamento
            </Button>
          </div>
          <BoxLancamentoAula aula={aula} listMatricula={listMatricula} />
          <div className="row-revisao">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Alunos" key="1">
                <MarcarPresencaAlunosTable
                  isView={isView}
                  listMatricula={listMatricula}
                  onChangeFrequencia={onChangeFrequencia}
                  onChangeFrequenciaTarefa={onChangeFrequenciaTarefa}
                  onChangeFrequenciaReforco={onChangeFrequenciaReforco}
                  getFrequenciaValue={getFrequenciaValue}
                  getFrequenciaValueTarefa={getFrequenciaValueTarefa}
                  getFrequenciaValueReforco={getFrequenciaValueReforco}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
