import { Progress } from 'antd';
import useTurmaDetalhe from '../../useTurmaDetalhe';
import useQuantidadeAulaRealizadas from '../../hooks/useQuantidadeAulaRealizadas';
import usePorcentagem from './usePorcentagem';
import './porcentagem-aulas.css';

export default function PorcentagemAulas() {
  const { turma } = useTurmaDetalhe();
  const quantidadeAulasRealizadas = useQuantidadeAulaRealizadas();
  const getPorcentagem = usePorcentagem();

  return (
    <div className="porcentagem-aulas">
      <Progress
        type="circle"
        percent={getPorcentagem()}
        width={80}
        strokeColor="var(--primary-color)"
        trailColor="rgba(203, 212, 237, 0.2);"
      />
      <div className="porcentagem-aulas__andamento-aulas">
        <h3 className="andamento-aulas__titulo">Andamento</h3>
        <h6 className="andamento-aulas__subtitulo">
          {quantidadeAulasRealizadas()} de {turma?.aulas?.length} aulas
        </h6>
      </div>
    </div>
  );
}
