import { Tags } from '../../../../components/Tags';
import useTurmaDetalhe from '../../useTurmaDetalhe';
import ImgYoutube from '../../../../assets/img/box-curso/youtube.svg';
import './box-data-turma-detalhe.css';
import useQuantidadeAulaRealizadas from '../../hooks/useQuantidadeAulaRealizadas';
import StatusTurma from '../StatusTurma';
import {
  SchoolOutlined,
  ScheduleOutlined,
  SentimentSatisfiedAltOutlined,
} from '@mui/icons-material';
import dayjs from 'dayjs';

export default function BoxDataTurmaDetalhe() {
  const { turma } = useTurmaDetalhe();
  const quantidadeAulasRealizadas = useQuantidadeAulaRealizadas();

  return (
    <div className="box-data-turma">
      {turma && (
        <>
          <div className="avatar-turma">
            <img src={ImgYoutube} alt="Imagem avatar da turma" />
            <Tags tipo={turma.descricao} />
          </div>
          <div className="data-turma">
            <div className="data-turma__turma-descricao">
              <h4 className="turma-descricao__titulo">{turma.descricao}</h4>
              <StatusTurma turma={turma} />
              <p className="turma-descricao__data">
                {dayjs(turma.dataInicio).format('DD/MM/YYYY')} -{' '}
                {dayjs(turma.dataFim).format('DD/MM/YYYY')} (
                {dayjs(turma.dataFim).diff(turma.dataInicio, 'day')})
              </p>
            </div>
            <div className="data-turma__info-icones">
              <div className="info-icones">
                <SentimentSatisfiedAltOutlined />
                <p>{turma.matriculas?.length ?? 0} alunos ativos</p>
              </div>
              <div className="info-icones">
                <SchoolOutlined />
                <p>
                  {quantidadeAulasRealizadas()}/{turma.aulas?.length}
                </p>
              </div>
              <div className="info-icones">
                <ScheduleOutlined />
                <p>
                  {(
                    (turma.curso?.duracaoMinutos / turma.aulas?.length) *
                    quantidadeAulasRealizadas()
                  ).toFixed(0)}
                  /{turma.curso?.duracaoMinutos} minutos
                </p>
              </div>
            </div>
            <div className="data-turma__datas-horarios">
              {turma.horarios.map(({ diaSemana, horaInicio, horaFim, id }) => (
                <div className="datas-horarios" key={id}>
                  <h6 className="datas-horarios__dia-semana">{diaSemana}</h6>
                  <p className="datas-horarios__horario">
                    {horaInicio.slice(0, -3)} - {horaFim.slice(0, -3)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
