import { Button, Radio, Space } from 'antd';
import { useEffect, useState } from 'react';
import { ModalConfirmacao } from '../../components/Modal/confirmacao';
import { useGlobalContext } from '../../context/GlobalContext';
import {
  IAlternativaDTO,
  IQuestaoDTO,
  IQuestaoFullDTO,
  IRespostaDTO,
  ITopicoAulaDTO,
  IUsuarioDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAcaQuestaoService } from '../../services/aca-questao';
import { useAcaRespostaService } from '../../services/aca-resposta';

export const AulaAvaliacao = ({ topico, setAvaliacaoResposta }) => {
  const { setIsGlobalLoading, usuario } = useGlobalContext();
  const questaoService = useAcaQuestaoService();
  const respostaService = useAcaRespostaService();
  const [predicate] = useState<Predicate>(new Predicate());
  const [data, setData] = useState<IQuestaoFullDTO[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alternativas, setAlternativas] = useState<IRespostaDTO[]>([]);
  const [resposta, setResposta] = useState<IRespostaDTO[]>([]);

  const findQuestao = () => {
    setIsGlobalLoading(true);
    predicate.addOption('topicoAula.id', topico?.id);
    questaoService
      .findFullList(predicate)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const findResposta = () => {
    setIsGlobalLoading(true);
    predicate.addOption('topicoAula.id', topico?.id);
    predicate.addOption('usuario.id', usuario?.id);
    respostaService
      .findList(predicate)
      .then((response) => {
        setResposta(response.data);
        setAvaliacaoResposta(response.data.length <= 0);
      })
      .finally(() => setIsGlobalLoading(false));
  };

  const onChange = (idAlternativa: number, questao: IQuestaoFullDTO) => {
    const respostas = alternativas.filter((a) => a.questao.id !== questao.id);
    respostas.push({
      alternativa: { id: idAlternativa } as IAlternativaDTO,
      questao: { id: questao.id } as IQuestaoDTO,
      topicoAula: { id: questao.topicoAula.id } as ITopicoAulaDTO,
      usuario: { id: usuario?.id } as IUsuarioDTO,
      id: null,
      ativo: true,
    });
    setAlternativas(respostas);
  };

  const onChangeModal = () => {
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const converterNumeroLetra = (indexAlternativa) => {
    if (indexAlternativa >= 0 && indexAlternativa < 26) {
      return String.fromCharCode(65 + indexAlternativa);
    } else {
      throw new Error('Índice fora do intervalo válido (0 a 25).');
    }
  };

  const renderButton = () => {
    if (resposta.length <= 0) {
      return (
        <Button onClick={onChangeModal} className="button-primary">
          Finalizar questionário
        </Button>
      );
    }
  };

  const avaliacaoRespondida = (questao, alternativa) => {
    const alternativaSelecionada = alternativas.find(
      (a) =>
        a?.questao?.id === questao?.id &&
        a?.alternativa?.id === alternativa?.id,
    );
    const respostaSalva = resposta.find(
      (r) =>
        r?.questao?.id === questao?.id &&
        r?.alternativa?.id === alternativa?.id,
    );

    if (alternativaSelecionada && !resposta.find((r) => r?.id)?.id) {
      return 'box-radio-select'; // Alternativa selecionada, mas ainda não respondida (fundo padrão)
    }

    if (resposta.find((r) => r?.id)) {
      if (alternativa?.resposta) {
        return 'box-radio-select-correto'; // Resposta correta (fundo verde)
      } else if (
        respostaSalva?.alternativa?.id === alternativa.id &&
        !alternativa?.resposta
      ) {
        return 'box-radio-select-errado'; // Resposta salva é incorreta (fundo vermelho)
      }
    }
  };

  const calcularNota = () => {
    const totalQuestoes = data.length;
    let respostasCorretas = 0;

    data.forEach((questao) => {
      const alternativaCorreta = questao.alternativas.find((a) => a.resposta);
      const respostaSelecionada = resposta.find(
        (r) => r.questao.id === questao.id,
      )?.alternativa;
      if (
        alternativaCorreta &&
        respostaSelecionada &&
        alternativaCorreta.id === respostaSelecionada.id
      ) {
        respostasCorretas++;
      }
    });

    const nota = (respostasCorretas / totalQuestoes) * 100;
    return nota.toFixed(2);
  };

  useEffect(() => {
    findQuestao();
    findResposta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="aula-box" className="d-flex-row j-between-a-start mb-20">
        <div
          id="box-aula-white"
          className="box-white"
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 className="title-avaliacao mb-10">Avaliação</h2>
            <h2 className="title-avaliacao mb-10">
              {resposta.length <= 0 ? null : 'Nota: ' + calcularNota()}
            </h2>
          </div>
          {data &&
            data.map((questao, index) => (
              <div key={index}>
                <div className="flex-container">
                  <p>
                    <strong>{index + 1}.</strong>
                  </p>
                  {questao?.descricao?.includes('<') ? (
                    <p
                      dangerouslySetInnerHTML={{ __html: questao?.descricao }}
                    ></p>
                  ) : (
                    <p>
                      {index + 1}.{questao?.descricao}
                    </p>
                  )}
                </div>
                <div className="d-flex-row j-start-a-start w-100 mb-10">
                  <Radio.Group
                    onChange={(e) => onChange(e.target.value, questao)}
                    defaultValue={
                      resposta.find((a) => a?.questao?.id === questao?.id)
                        ?.alternativa?.id
                    }
                    value={
                      resposta.find((a) => a?.questao?.id === questao?.id)
                        ?.alternativa?.id
                    }
                    disabled={resposta.find((r) => r?.id)?.id ? true : false}
                  >
                    <Space direction="vertical">
                      {questao &&
                        questao.alternativas.map((alternativa, index) => (
                          <div
                            key={index}
                            className={`${avaliacaoRespondida(questao, alternativa)} box-radio mb-10`}
                          >
                            <Radio
                              value={alternativa?.id}
                              className="alternativa-descricao"
                            >
                              <div
                                className="alternativa-descricao"
                                style={{ paddingLeft: '10px' }}
                              >
                                Alternativa {converterNumeroLetra(index)}
                                {alternativa?.descricao?.includes('<') ? (
                                  <>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: alternativa.descricao,
                                      }}
                                    ></p>
                                  </>
                                ) : (
                                  <>
                                    <p>{alternativa.descricao}</p>
                                  </>
                                )}
                              </div>
                            </Radio>
                          </div>
                        ))}
                    </Space>
                  </Radio.Group>
                </div>
                {resposta.find((a) => a?.questao?.id === questao?.id)
                  ?.alternativa?.id ? (
                  <div>
                    <h2 className="title-avaliacao mb-10">
                      Resposta correta: Alternativa{' '}
                      {converterNumeroLetra(
                        questao?.alternativas?.findIndex((a) => a?.resposta),
                      )}
                    </h2>
                    <p className="descricao-avaliacao mb-20">
                      {
                        resposta.find((r) => r?.questao?.id === questao?.id)
                          ?.alternativa?.justificativa
                      }
                    </p>
                  </div>
                ) : null}
                {data && data.length > 1 ? <div className="line" /> : null}
              </div>
            ))}

          <div className="d-flex-row j-end-a-center">{renderButton()}</div>
        </div>
      </div>
      <ModalConfirmacao
        open={isOpen}
        onClose={onClose}
        findResposta={findResposta}
        alternativas={alternativas}
      />
    </>
  );
};
