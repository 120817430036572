import { ITurmaDTO } from '../../../../models/happy-code-api.model';
import {
  isAfterDate,
  isBeforeDate,
  isDateBetween,
} from '../../../../util/format';

interface useStatusTurmaProps {
  turma: ITurmaDTO;
}
export default function useStatusTurma({ turma }: useStatusTurmaProps) {
  const { dataInicio, dataFim } = turma;

  let dotColor = '';
  let statusText = '';

  if (isBeforeDate(dataInicio)) {
    dotColor = 'dot-orange';
    statusText = 'Não Iniciado';
  }
  if (isDateBetween(dataInicio, dataFim)) {
    dotColor = 'dot-green';
    statusText = 'Em andamento';
  }
  if (isAfterDate(dataFim)) {
    dotColor = 'dot-orange';
    statusText = 'Finalizado';
  }

  return {
    dotColor,
    statusText,
  };
}
