import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Predicate } from '../../../models/predicate.model';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useTrilhaService } from '../../../services/trilha.service';
import {
  ITrilhaCleanDTO,
  ITrilhaFullDTO,
} from '../../../models/happy-code-api.model';
import { PageableResponse } from '../../../models/response.model';
import { Operators } from '../../../enum/operators.enum';

export default function useAllCursos() {
  const { usuario, setIsGlobalLoading } = useGlobalContext();

  const [searchValue, setSearchValue] = useState('');

  const [trilhaFilter, setTrilhaFilter] = useState<ITrilhaCleanDTO>();

  const addPredicateGenericOptions = useCallback(
    (page?: number): Predicate => {
      const predicate = new Predicate();

      predicate.addOption('ativo', true);
      predicate.addOption('cursos.ativo', true);

      if (page) predicate.setPage(page);

      if (searchValue)
        predicate.addOption('cursos.nome', searchValue, Operators.CONTAINS);

      return predicate;
    },
    [searchValue],
  );

  const trilhaService = useTrilhaService();

  const [trilhasData, setTrilhasData] = useState<ITrilhaCleanDTO[]>();

  const findTrilhasFilter = useCallback(
    async (predicate: Predicate) => {
      if (!usuario) return;

      const { acessoB2c, acessoB2b, acessoComercial } = usuario;

      if (trilhaFilter) predicate.addOption('id', trilhaFilter.id);

      await trilhaService
        .findTrilhaFilterCustom(
          acessoB2c,
          acessoB2b,
          acessoComercial,
          predicate,
        )
        .then(({ data }) => setTrilhasData(data));
    },
    [setTrilhasData, trilhaFilter, usuario, setIsGlobalLoading],
  );

  const [paginatedTrilhas, setPaginatedTrilhas] =
    useState<PageableResponse<ITrilhaFullDTO>>();

  const findPaginatedTrilhas = useCallback(
    async (predicate: Predicate) => {
      if (!usuario) return;

      const { acessoB2c, acessoB2b, acessoComercial } = usuario;

      await trilhaService
        .findFullCustom(acessoB2c, acessoB2b, acessoComercial, predicate)
        .then(({ data }) => setPaginatedTrilhas(data));
    },
    [setPaginatedTrilhas, usuario, setIsGlobalLoading],
  );

  useEffect(() => {
    const controller = new AbortController();

    const predicate = addPredicateGenericOptions();

    Promise.all([
      findTrilhasFilter(predicate),
      findPaginatedTrilhas(predicate),
    ]);

    return () => controller.abort();
  }, [addPredicateGenericOptions, findPaginatedTrilhas, findTrilhasFilter]);

  const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
    event.preventDefault();

    const predicate = addPredicateGenericOptions(page);

    findPaginatedTrilhas(predicate);
  };

  return {
    trilhasData,
    setSearchValue,
    searchValue,
    paginatedTrilhas,
    onPageChange,
    trilhaFilter,
    setTrilhaFilter,
  };
}
