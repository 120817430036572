import http from '../helpers/http-common';
import {
  IQuestaoCleanDTO,
  IQuestaoDTO,
  IQuestaoFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

interface Props {
  id?: number;
}
export const useAcaQuestaoService = () => {
  const resourceURL = 'aca-questao';

  const saveFullAll = (
    body: any,
  ): Promise<IAxiosResponseCustom<IQuestaoFullDTO>> =>
    http.post(`${resourceURL}/full/all`, body);

  const patchFullAll = (
    idTopico: number,
    body: any,
  ): Promise<IAxiosResponseCustom<IQuestaoFullDTO>> =>
    http.patch(`${resourceURL}/patch/full/all/${idTopico}`, body);

  return {
    ...baseFindList<IQuestaoCleanDTO, IQuestaoDTO, IQuestaoFullDTO>(
      resourceURL,
    ),
    ...baseFind<IQuestaoCleanDTO, IQuestaoDTO, IQuestaoFullDTO>(resourceURL),
    ...baseFindList<IQuestaoCleanDTO, IQuestaoDTO, IQuestaoFullDTO>(
      resourceURL,
    ),
    ...baseFindById<IQuestaoCleanDTO, IQuestaoDTO, IQuestaoFullDTO>(
      resourceURL,
    ),
    ...baseCreate<IQuestaoCleanDTO, IQuestaoDTO, IQuestaoFullDTO>(resourceURL),
    ...baseUpdate<IQuestaoCleanDTO, IQuestaoDTO, IQuestaoFullDTO>(resourceURL),
    delete: (body: Props) => http.delete(`${resourceURL}/${body.id}`),
    patchFullAll,
    saveFullAll,
  };
};
