import { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { ITrilhaFullDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useTrilhaService } from '../../services/trilha.service';

const useCursos = () => {
  const { setIsGlobalLoading, usuario } = useGlobalContext();

  const predicate = new Predicate();

  const trilhaService = useTrilhaService();

  const [destaques, setDestaques] = useState<ITrilhaFullDTO[]>();

  const findCursos = useCallback(async () => {
    setIsGlobalLoading(true);

    predicate.addOption('ativo', true);

    await trilhaService
      .findDestaques(
        usuario?.acessoB2c,
        usuario?.acessoB2b,
        usuario?.acessoComercial,
        predicate,
      )
      .then(({ data }) => setDestaques(data))
      .finally(() => setIsGlobalLoading(false));
  }, [
    setIsGlobalLoading,
    usuario?.acessoB2b,
    usuario?.acessoB2c,
    usuario?.acessoComercial,
  ]);

  useEffect(() => {
    findCursos();
  }, [findCursos]);

  return {
    destaques,
  };
};

export default useCursos;
