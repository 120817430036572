import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Progress, Select, Steps } from 'antd';
// import "antd/dist/antd.css";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { Operators } from '../../enum/operators.enum';
import '../../global.css';
import { Predicate } from '../../models/predicate.model';
import { useCursoService } from '../../services/curso.service';
import { ICursoCleanDTO } from './../../models/happy-code-api.model';
import './style.css';
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker }: any = DatePicker;

interface Form {
  id: number;
  descricao: string;
  dataInicio: any;
  dataFim: any;
  curso: any;
}

export const TurmasCreateStep1: React.FunctionComponent = () => {
  const { setIsGlobalLoading, unidade } = useGlobalContext();
  const { turma, setTurma } = useTurmaContext();
  const { Step } = Steps;
  const [isOpen, setIsOpen] = useState(false);
  const [listCurso, setListCurso] = useState<ICursoCleanDTO[]>([]);
  const [cursoPredicate] = useState<Predicate>(new Predicate(100));
  const [descricao, setDescricao] = useState<string>();
  const [form, setForm] = useState<Form>({
    id: null,
    descricao: null,
    dataInicio: null,
    dataFim: null,
    curso: { id: null },
  });

  const navigate = useNavigate();
  const cursoService = useCursoService();

  const findCurso = () => {
    setIsGlobalLoading(true);
    cursoPredicate.addOption('cursoUnidades.unidade.id', unidade.id);
    cursoService
      .findClean(cursoPredicate)
      .then((response) => setListCurso(response.data.content))
      .finally(() => setIsGlobalLoading(false));
  };

  useEffect(() => {
    findCurso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (turma) {
      form.curso.id = turma.curso.id;
      form.dataFim = turma.dataFim;
      form.dataInicio = turma.dataInicio;
      form.descricao = turma.descricao;
      form.id = turma.id;
      setForm({ ...form });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turma]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (descricao !== undefined) {
        cursoPredicate.addOption('descricao', descricao, Operators.CONTAINS);
        findCurso();
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);

  const onSearch = (searchText: string) => {
    setDescricao(searchText);
  };

  const handleSubmit = () => {
    if (form.descricao && form.dataInicio && form.dataFim && form.curso.id) {
      turma.codigo = form.descricao;
      setTurma({ ...turma, ...form });
      navigate('/turmas/create/step-2');
    }
  };

  const handleCursoSelect = (data) => {
    setForm({ ...form, curso: { id: data.key } });
    setTurma({
      ...turma,
      curso: {
        ...turma.curso,
        id: data.key,
        descricao: data.value,
      },
    });
  };

  const handleDescricao = (descricao) => {
    setForm({ ...form, descricao });
    setTurma({ ...turma, descricao });
  };

  const getPeriodo = (): any => {
    if (form.dataInicio && form.dataFim) {
      return [
        dayjs(form.dataInicio, 'YYYY-MM-DD'),
        dayjs(form.dataFim, 'YYYY-MM-DD'),
      ];
    }
    return null;
  };

  return (
    <div className="container">
      <Header />
      <div className="progress">
        <Progress
          className="progress-user"
          percent={25}
          strokeLinecap="square"
          showInfo={false}
        />
      </div>
      <div className="menu-user">
        <h3>Criação de turma</h3>
        <Steps direction="vertical" current={0}>
          <Step title="Dados Básicos" />
          <Step title="Instrutor" />
          <Step title="Dias de semana" />
          {/* <Step title="Revisão" /> */}
        </Steps>
      </div>
      <div className="franquia">
        <div className="space1"></div>
        <div className="user-row" id="row-aluno">
          <h3>Dados básicos de turma</h3>
          <div className="input-box">
            <p>ID</p>
            <Input disabled={true} value={form.id} className="input" />
          </div>
          <div className="input-box">
            <p>
              Nome da Turma <span id="ob">*</span>
            </p>
            <Input
              value={form.descricao}
              onChange={(e) => handleDescricao(e.target.value)}
              placeholder="Nome turma"
            />
          </div>
          <div className="inputs-box">
            <div className="input-box" id="box-aluno">
              <p>
                Curso<span id="ob">*</span>
              </p>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Selecione uma turma"
                optionFilterProp="children"
                value={turma.curso.descricao}
                onChange={(v, data) => handleCursoSelect(data)}
                onSearch={onSearch}
              >
                {listCurso.map((curso: ICursoCleanDTO) => (
                  <Option key={curso.id} value={curso.descricao}>
                    {curso.descricao}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-box" id="box-aluno">
              <p>
                Período<span id="ob">*</span>
              </p>
              <RangePicker
                value={getPeriodo()}
                onChange={(values) =>
                  setForm({
                    ...form,
                    dataInicio: values[0].format('YYYY-MM-DD'),
                    dataFim: values[1].format('YYYY-MM-DD'),
                  })
                }
                format={'DD-MM-YYYY'}
              />
            </div>
          </div>

          <div className="botoes">
            <Link to="/turmas">
              <Button className="button-second" style={{ width: 345 }}>
                Voltar
              </Button>
            </Link>
            <div onClick={() => handleSubmit()}>
              <Button className="button-primary" style={{ width: 345 }}>
                Próximo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Button
        onClick={() => setIsOpen(true)}
        className="button-fechar"
        icon={<CloseOutlined />}
      />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Você deseja cancelar a adição desta Nova turma?"
        subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
        textButton1="Não"
        textButton2="Sim, Cancelar"
        styleButton1="button-line"
        styleButton2="button-danger"
        link2="/turmas"
      />
    </div>
  );
};
