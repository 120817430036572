import { Button } from 'antd';
import { PresencaReforco } from '../../presenca-reforco';
import { PresencaSwitch } from '../../presenca-switch';
import { TarefaSwitch } from '../../tarefa-switch';
import { useNavigate } from 'react-router-dom';
import {
  IFrequenciaDTO,
  IMatriculaFullDTO,
} from '../../../../models/happy-code-api.model';
import './marcar-presenca-alunos-table.css';

interface MarcarPresencaAlunosTableProps {
  listMatricula: IMatriculaFullDTO[];
  isView: boolean;
  onChangeFrequencia: (presenca: string, matricula: IMatriculaFullDTO) => void;
  onChangeFrequenciaTarefa: (
    tarefa: string,
    matricula: IMatriculaFullDTO,
  ) => void;
  onChangeFrequenciaReforco: (
    reforco: boolean,
    matricula: IMatriculaFullDTO,
  ) => void;
  getFrequenciaValue: (matricula: IMatriculaFullDTO) => IFrequenciaDTO;
  getFrequenciaValueTarefa: (matricula: IMatriculaFullDTO) => IFrequenciaDTO;
  getFrequenciaValueReforco: (matricula: IMatriculaFullDTO) => IFrequenciaDTO;
}

export default function MarcarPresencaAlunosTable({
  listMatricula,
  isView,
  onChangeFrequencia,
  onChangeFrequenciaTarefa,
  onChangeFrequenciaReforco,
  getFrequenciaValue,
  getFrequenciaValueTarefa,
  getFrequenciaValueReforco,
}: MarcarPresencaAlunosTableProps) {
  const navigate = useNavigate();

  return (
    <div className="marcar-presenca-alunos-table-wrapper">
      <div className="marcar-presenca-alunos-table">
        <div className="marcar-presenca-alunos-table__header">
          <div className="header__cell" style={{ width: '50%' }}>
            <span>NOME</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>MATRICULA</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>PRESENÇA</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>TAREFA</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>REFORCO</span>
          </div>
          <div className="header__cell" style={{ width: '20%' }}>
            <span>OBSERVAÇÕES</span>
          </div>
        </div>
        {listMatricula &&
          listMatricula.map((matricula) => (
            <div
              key={matricula.id}
              className="marcar-presenca-alunos-table__row"
            >
              <div className="row__aluno-cell" style={{ width: '50%' }}>
                <p className="aluno-cell__aluno-nome">
                  {matricula.alunoPessoaNome}
                </p>
                <div className="aluno-nome__status">
                  <div
                    className={`
                    status__dot
                    ${
                      matricula.ativo
                        ? 'status__dot-ativo'
                        : 'status__dot-desativado'
                    }`}
                  ></div>
                  <p>{matricula.ativo ? 'Ativo' : 'Desativado'}</p>
                </div>
              </div>
              <div className="row__cell" id="alunos" style={{ width: '15%' }}>
                <p>#{matricula.id}</p>
              </div>
              <div className="row__cell" style={{ width: '15%' }}>
                <PresencaSwitch
                  value={
                    getFrequenciaValue(matricula)?.frequenciaTipo?.descricao
                  }
                  onChange={(value) => onChangeFrequencia(value, matricula)}
                />
              </div>
              <div className="row__cell" style={{ width: '15%' }}>
                <TarefaSwitch
                  value={
                    getFrequenciaValueTarefa(matricula)?.frequenciaTarefa
                      ?.descricao
                  }
                  onChange={(value) =>
                    onChangeFrequenciaTarefa(value, matricula)
                  }
                />
              </div>
              <div className="row__cell" style={{ width: '15%' }}>
                <PresencaReforco
                  value={getFrequenciaValueReforco(matricula)?.reforco}
                  onChange={(value) =>
                    onChangeFrequenciaReforco(value, matricula)
                  }
                />
              </div>
              <div
                className="row__cell"
                id="aluno-responsavel"
                style={{ width: '20%' }}
              >
                <Button
                  onClick={() =>
                    navigate(
                      `/turmas/observacoes/aluno/${
                        matricula.aluno.id
                      }/frequencia/${getFrequenciaValue(matricula)?.id}`,
                    )
                  }
                  disabled={!isView}
                  className="button-primary"
                >
                  Adicionar
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
