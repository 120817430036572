import { Button, InputNumber } from 'antd';
import { IMatriculaProvaCleanDTO } from '../../models/happy-code-api.model';
import './style.css';
import Accordion from '../Accordion';
import useHandleModalProva from './useHandleModalProva';

interface ModalLancarNotaProps {
  open: boolean;
  onClose: () => void;
  prova: IMatriculaProvaCleanDTO;
  idMatricula: number;
}

export default function ModalLancarNota({
  open,
  onClose,
  prova,
  idMatricula,
}: ModalLancarNotaProps) {
  const {
    handleInputChange,
    handleSubmit,
    mediaTerm1,
    mediaTerm2,
    mediaFinal,
  } = useHandleModalProva({
    open,
    onClose,
    prova,
    idMatricula,
  });

  if (!open) return null;

  return (
    <div className="overlay-background">
      <form className="modal-lancar-nota" onSubmit={handleSubmit}>
        <h3 className="modal-lancar-nota__titulo">Lançar nota</h3>
        <Accordion titulo="Média Term. 1" info={`Total: ${mediaTerm1}`}>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PE1:</label>
            <InputNumber
              defaultValue={prova?.notaPrimeiraPE1 ?? 0}
              max={10}
              min={0}
              onChange={(e: number) => handleInputChange(e, 'notaPrimeiraPE1')}
            />
          </fieldset>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PC1 S:</label>
            <InputNumber
              defaultValue={prova?.notaPrimeiraPC1S ?? 0}
              max={10}
              min={0}
              onChange={(e: number) => handleInputChange(e, 'notaPrimeiraPC1S')}
            />
          </fieldset>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PC1 W:</label>
            <InputNumber
              defaultValue={prova?.notaPrimeiraPC1W ?? 0}
              max={10}
              min={0}
              onChange={(e: number) => handleInputChange(e, 'notaPrimeiraPC1W')}
            />
          </fieldset>
        </Accordion>

        <Accordion titulo="Média Term. 2" info={`Total: ${mediaTerm2}`}>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PE2:</label>
            <InputNumber
              defaultValue={prova?.notaSegundaPE2 ?? 0}
              max={10}
              min={0}
              onChange={(e: number) => handleInputChange(e, 'notaSegundaPE2')}
            />
          </fieldset>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PC2 S:</label>
            <InputNumber
              defaultValue={prova?.notaSegundaPC2S ?? 0}
              max={10}
              min={0}
              onChange={(e: number) => handleInputChange(e, 'notaSegundaPC2S')}
            />
          </fieldset>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PC2 L:</label>
            <InputNumber
              defaultValue={prova?.notaSegundaPC2L ?? 0}
              max={10}
              min={0}
              onChange={(e: number) => handleInputChange(e, 'notaSegundaPC2L')}
            />
          </fieldset>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PC2 R:</label>
            <InputNumber
              defaultValue={prova?.notaSegundaPC2R ?? 0}
              max={10}
              min={0}
              onChange={(e: number) => handleInputChange(e, 'notaSegundaPC2R')}
            />
          </fieldset>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PC2 Lang:</label>
            <InputNumber
              defaultValue={prova?.notaSegundaPC2Lang ?? 0}
              max={10}
              min={0}
              onChange={(e: number) =>
                handleInputChange(e, 'notaSegundaPC2Lang')
              }
            />
          </fieldset>
          <fieldset className="modal-lancar-nota__text-field">
            <label className="text-field__label">Nota PC2 W:</label>
            <InputNumber
              defaultValue={prova?.notaSegundaPC2W ?? 0}
              max={10}
              min={0}
              onChange={(e: number) => handleInputChange(e, 'notaSegundaPC2W')}
            />
          </fieldset>
        </Accordion>

        <span>Média Final: {mediaFinal}</span>

        <div className="modal-lancar-nota__buttons">
          <Button
            onClick={onClose}
            style={{ minWidth: 145, padding: '3px' }}
            className="button-second"
            htmlType="button"
          >
            Cancelar
          </Button>
          <Button
            className="button-primary"
            style={{ marginLeft: 8, minWidth: 145 }}
            htmlType="submit"
          >
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
}
