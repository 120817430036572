/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthEnum } from '../enum/auth.enum';
import { Auth } from '../models/auth.model';
import {
  IUnidadeCleanDTO,
  IUserAuthDTO
} from '../models/happy-code-api.model';
import { useUsuarioCursoService } from '../services/usuario-curso.service';
import { getLocalStorageItem, setObject } from '../util/store';

//Tipando as Props do contexto
interface PropsUserContext {
  isGlobalLoading: boolean;
  usuario: IUserAuthDTO;
  unidade: IUnidadeCleanDTO;
  token: Auth;
  setToken: React.Dispatch<React.SetStateAction<Auth>>;
  setUsuario: React.Dispatch<React.SetStateAction<IUserAuthDTO>>;
  setUnidade: React.Dispatch<React.SetStateAction<IUnidadeCleanDTO>>;
  setIsGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchHappyCoins: React.Dispatch<React.SetStateAction<boolean>>;
  happyCoins: number;
}

//Valor default do contexto
const DEFAULT_VALUE = {
  isGlobalLoading: false,
  usuario: {} as IUserAuthDTO,
  unidade: {} as IUnidadeCleanDTO,
  token: undefined,
  happyCoins: 0,
  logout: () => { }, //função de inicialização
  setToken: () => { }, //função de inicialização
  setUsuario: () => { }, //função de inicialização
  setUnidade: () => { }, //função de inicialização
  setIsGlobalLoading: () => { }, //função de inicialização
  setUsuarioForm: () => { }, //função de inicialização
  fetchHappyCoins: () => { }, //função de inicialização
  // setPopLoading: () => { }, //função de inicialização
};

const nameStorageLoginUnidade = '@HappyCodeAcademy/login/unidade';

//criando nosso contexto UserContext
const GlobalContext = createContext<PropsUserContext>(DEFAULT_VALUE);

interface GlobalContextProviderProps {
  children: React.ReactNode;
}

/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
}: GlobalContextProviderProps) => {
  const [isGlobalLoading, setIsGlobalLoading] = useState(
    DEFAULT_VALUE.isGlobalLoading,
  );
  const [usuario, setUsuarioGlobal] = useState(DEFAULT_VALUE.usuario);
  const [unidade, setUnidadeGlobal] = useState(DEFAULT_VALUE.unidade);
  const [token, setTokenGlobal] = useState(DEFAULT_VALUE.token);
  const [happyCoins, setHappyCoins] = useState(null);
  const usuarioCursoService = useUsuarioCursoService();
  const setToken = (token) => {
    setObject(AuthEnum.TOKEN_KEY, token);
    setTokenGlobal(token);
  };
  const setUsuario = (usuario) => {
    setObject(AuthEnum.USER_KEY, usuario);
    setUsuarioGlobal(usuario);
  };
  const setUnidade = (unidade) => {
    setObject(nameStorageLoginUnidade, unidade);
    setUnidadeGlobal(unidade);
  };

  useEffect(() => {
    const usuario = getLocalStorageItem(AuthEnum.USER_KEY);
    if (usuario) {
      setUsuarioGlobal(usuario);
    }

    const unidade = getLocalStorageItem(nameStorageLoginUnidade);
    if (unidade) {
      setUnidadeGlobal(unidade);
    }

    const token = getLocalStorageItem(AuthEnum.TOKEN_KEY);
    if (token) {
      setTokenGlobal(token);
    }
  }, []);

  const fetchHappyCoins = () => {
    usuarioCursoService.getHappyCoins().then((response) => {
      setHappyCoins(response.data?.total);
    });
  };

  // useEffect(() => {
  //   if (!!token && !!usuario && (happyCoins === null || happyCoins === undefined)) {
  //     fetchHappyCoins();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [happyCoins, usuario])

  return (
    <GlobalContext.Provider
      value={{
        isGlobalLoading,
        usuario,
        unidade,
        token,
        setToken,
        setUsuario,
        setUnidade,
        setIsGlobalLoading,
        happyCoins,
        fetchHappyCoins,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = (): PropsUserContext => {
  const context = useContext(GlobalContext);
  if (!context)
    throw new Error(
      'useGlobalContext must be used within a GlobalContextProvider',
    );
  return context;
};
