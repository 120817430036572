import { memo } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/header/logo.svg';
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { Avatar } from '../Avatar';
import './style.css';

const Header = memo(() => {
  //export default function Header() {
  const { usuario } = useGlobalContext();
  return (
    <header>
      <div className="header">
        <Link to={usuario.perfil === 'SEDE' ? '/' : '/franquia'}>
          <img src={logo} alt={'logo'} className="logo" />
        </Link>

        <Link to="/usuarios/profile">
          <div className="profile-box">
            <div className="profile">
              <Avatar base64={usuario?.arquivo} size={30} />
              <div className="name">
                <h6>{usuario?.usuario}</h6>
                <span>{usuario?.perfil}</span>
              </div>
              {/* <Unicons.UilAngleDown size="20" color="#92A9CB" className="angle-down" /> */}
            </div>
          </div>
        </Link>
      </div>
    </header>
  );
});

Header.displayName = 'Header';

export default Header;
