import { ChangeEvent } from 'react';
import { PageableResponse } from '../../../../models/response.model';
import { IMatriculaFullDTO } from '../../../../models/happy-code-api.model';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { Pagination } from '@material-ui/lab';
import './aluno-table.css';
import StatusMatricula from '../StatusMatricula';

interface AlunoTableProps {
  listMatricula: PageableResponse<IMatriculaFullDTO>;
  onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
}

export default function AlunoTable({
  listMatricula,
  onPageChange,
}: AlunoTableProps) {
  const navigate = useNavigate();

  return (
    <div className="aluno-table-wrapper">
      <div className="aluno-table">
        <div className="aluno-table__header">
          <div className="header__cell" style={{ width: '50%' }}>
            <span>NOME</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>CÓDIGO</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>MATRICULA</span>
          </div>
          <div className="header__cell" style={{ width: '15%' }}>
            <span>FALTA</span>
          </div>
          <div className="header__cell" style={{ width: '20%' }}>
            <span>SKILLS</span>
          </div>
        </div>
        {listMatricula?.content.length === 0 && 'Nenhum Aluno'}
        {listMatricula &&
          listMatricula.content.map(
            ({
              id,
              alunoPessoaNome,
              statusMatricula: { codigo, descricao },
              frequencias,
              aluno,
            }) => (
              <div key={id} className="aluno-table__row">
                <div className="row__aluno-cell">
                  <p className="aluno-cell__aluno-nome">{alunoPessoaNome}</p>
                  <StatusMatricula
                    codigoStatusMatricula={codigo}
                    descricaoStatusMatricula={descricao}
                  />
                </div>
                <div className="row__cell" style={{ width: '15%' }}>
                  <p>#{aluno.id}</p>
                </div>
                <div className="row__cell" style={{ width: '15%' }}>
                  <p>#{id}</p>
                </div>
                <div className="row__cell" style={{ width: '15%' }}>
                  <p>
                    {frequencias.filter(({ frequencia }) => !frequencia).length}
                  </p>
                </div>
                <div className="row__cell" style={{ width: '20%' }}>
                  <Button
                    onClick={() => navigate(`/turmas/skills/aluno/${id}`)}
                    className="button-primary"
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
            ),
          )}
      </div>
      <div className="paginacao">
        <Pagination
          onChange={(event, page) => onPageChange(event, page - 1)}
          page={listMatricula ? listMatricula.pageable.pageNumber + 1 : 0}
          count={listMatricula ? listMatricula.totalPages : 0}
        />
      </div>
    </div>
  );
}
