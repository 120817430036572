import { StatusMatriculaEnum } from '../../../../enum/status-matricula.enum';
import { IStatusMatriculaDTO } from '../../../../models/happy-code-api.model';
import './status-matricula.css';

interface StatusMatriculaProps {
  codigoStatusMatricula: IStatusMatriculaDTO['codigo'];
  descricaoStatusMatricula: IStatusMatriculaDTO['descricao'];
}
export default function StatusMatricula({
  codigoStatusMatricula,
  descricaoStatusMatricula,
}: StatusMatriculaProps) {
  let corDot = 'status-matricula__dot-orange';

  const statusColors = {
    [StatusMatriculaEnum.Prematriculado]: 'status-matricula__dot-orange',
    [StatusMatriculaEnum.Matriculado]: 'status-matricula__dot-green',
    [StatusMatriculaEnum.Cancelado]: 'status-matricula__dot-orange',
    [StatusMatriculaEnum.Concluido]: 'status-matricula__dot-green',
  };

  if (codigoStatusMatricula in statusColors)
    corDot = statusColors[codigoStatusMatricula];

  return (
    <>
      <div className="status-matricula">
        <div className={corDot}></div>
        <p>{descricaoStatusMatricula}</p>
      </div>
    </>
  );
}
