import {
  addDays,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
} from 'date-fns';

export const formatMoney = (value: number) =>
  value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

export const formattedDate = (date) =>
  date && format(parseISO(date), 'dd/MM/yyyy');

// export const toMoment = (date) => date && moment(date);

export const isDateBetween = (dateIni, dateEnd) => {
  const today = new Date();
  dateIni = parseISO(dateIni).setHours(0, 0, 0, 0);
  dateEnd = parseISO(dateEnd).setHours(23, 59, 59, 0);
  return (
    isEqual(today, dateEnd) ||
    isEqual(today, dateIni) ||
    (isAfter(today, dateIni) && isBefore(today, dateEnd))
  );
};

export const isAfterDate = (date) => {
  const today = new Date();
  return isAfter(today, parseISO(date));
};

export const isBeforeDate = (date) => {
  const today = new Date();
  return isBefore(today, parseISO(date));
};

export const daysBetween = (date) =>
  isAfterDate(date) ? differenceInDays(new Date(), parseISO(date)) : 0;

export const formatMomentLocalDateTime = (date) =>
  date && date.format('YYYY-MM-DDT00:00:00.000');

export const formatMomentLocalDate = (date) =>
  date && date.format('YYYY-MM-DD');

export const nowDateTime = () => {
  return format(new Date(), "yyyy-MM-dd'T'00:00:00.000");
};

export const removeSecondsFromHour = (hour: string) =>
  hour.split(':').slice(0, -1).join(':');

export const nowDate = () => {
  return format(new Date(), 'yyyy-MM-dd');
};

export const nowDateAddDays = (qtd) => {
  return addDays(new Date(), qtd);
};

export const currencyFormatter = (value) => {
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

export const currencyParser = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0';
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat('pt-br').format(1111).replace(/1/g, '');
    const decimal = new Intl.NumberFormat('pt-br')
      .format(1.1)
      .replace(/1/g, '');
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

export const removeAcentos = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const formatBytes = (bytes: number) => {
  if (bytes < 1024) {
    return bytes + ' B';
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
  }
};

export const formatHours = (minutos) => {
  const horas = Math?.floor(minutos / 60);
  const minutosRestantes = minutos % 60;
  const horarioFormatado = `${horas
    ?.toString()
    .padStart(2, '0')}:${minutosRestantes?.toString().padStart(2, '0')}`;
  return horarioFormatado;
};
