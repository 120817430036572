import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import './presenca-switch.css';

type MyFunctionType = (value: any) => void;
interface Props {
  onChange: MyFunctionType;
  disabled?: boolean;
  value?: boolean;
}

export const PresencaReforco: React.FunctionComponent<Props> = ({
  onChange,
  disabled,
  value,
}: Props) => {
  const [state, setState] = useState<boolean>(false);

  useEffect(() => {
    onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setState(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      {state ? (
        <div
          onClick={() => !disabled && setState(!state)}
          className="presenca-item"
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
          <CloseCircleOutlined style={{ color: '#FF4D4F' }} />
          <p className="faltoso" style={{ color: '#FF4D4F' }}>
            Sim
          </p>
        </div>
      ) : (
        <div
          onClick={() => !disabled && setState(!state)}
          className="presenca-item"
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
          <CheckCircleOutlined style={{ color: '#1EBE5E' }} />
          <p className="faltoso" style={{ color: '#1EBE5E' }}>
            Não
          </p>
        </div>
      )}
    </div>
  );
};
