import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Progress, Radio, Space, Steps } from 'antd';
// import "antd/dist/antd.css";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import '../../global.css';
import { Predicate } from '../../models/predicate.model';
import { useUnidadePessoaService } from '../../services/unidade-pessoa.service';
import { IUnidadePessoaCleanDTO } from './../../models/happy-code-api.model';
import './style.css';

export const TurmasCreateStep2: React.FunctionComponent = () => {
  const { Step } = Steps;
  const [isOpen, setIsOpen] = useState(false);
  const [listProfessor, setListProfessor] = useState<IUnidadePessoaCleanDTO[]>(
    [],
  );
  const [professorSelected, setProfessorSelected] =
    useState<IUnidadePessoaCleanDTO>(null);
  const [professorPredicate] = useState<Predicate>(new Predicate(20));
  const [descricao, setDescricao] = useState<string>();

  const unidadePessoaService = useUnidadePessoaService();
  const { setIsGlobalLoading, unidade } = useGlobalContext();
  const navigate = useNavigate();
  const { turma, setTurma } = useTurmaContext();

  const findProfessor = () => {
    professorPredicate.addOption('tipoUnidadePessoa.id', 3);
    professorPredicate.addOption('unidade.id', unidade.id);
    return unidadePessoaService
      .findClean(professorPredicate)
      .then((response) => {
        setListProfessor(response.data.content);
      });
  };

  useEffect(() => {
    setIsGlobalLoading(true);
    findProfessor().finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (turma.unidadePessoa.id) {
      setProfessorSelected(turma.unidadePessoa);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (descricao !== undefined) {
        setIsGlobalLoading(true);
        professorPredicate.addOption('cpf', descricao);
        findProfessor().finally(() => setIsGlobalLoading(false));
      }
    }, 1500);
    return () => clearTimeout(typingTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);

  const handleSubmit = () => {
    if (professorSelected && professorSelected.id) {
      turma.unidadePessoa.id = professorSelected.id;
      setTurma({ ...turma });
      navigate('/turmas/create/step-3');
    }
  };

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress
          className="progress-user"
          percent={50}
          strokeLinecap="square"
          showInfo={false}
        />
      </div>
      <div className="menu-user">
        <h3>Criação de turma</h3>
        <Steps direction="vertical" current={1}>
          <Step title="Dados Básicos" />
          <Step title="Instrutor" />
          <Step title="Dias de semana" />
          {/* <Step title="Revisão" /> */}
        </Steps>
      </div>
      <div className="franquia">
        <div className="space1"></div>
        <div className="user-row" id="row-aluno">
          <h3>Selecione um instrutor</h3>
          <div
            className="input-text"
            style={{ width: '100%', marginBottom: 15 }}
          >
            <Input
              onChange={(e) => setDescricao(e.target.value)}
              placeholder="Buscar pelo CPF"
              prefix={<SearchOutlined style={{ color: '#6A7C96' }} />}
            />
          </div>
          <Radio.Group
            value={professorSelected?.id}
            onChange={(value) =>
              setProfessorSelected(
                listProfessor.find((i) => i.id === value.target.value),
              )
            }
            className="radio-group"
            name="radiogroup"
          >
            <Space direction="vertical">
              {listProfessor &&
                listProfessor.map((professor) => (
                  <div
                    key={professor.id}
                    className={
                      professorSelected &&
                      professorSelected?.id === professor?.id
                        ? 'radio-turma-selected'
                        : 'radio-turma'
                    }
                  >
                    <Radio value={professor.id}>
                      <p>{professor.pessoaNome}</p>
                    </Radio>
                  </div>
                ))}
            </Space>
          </Radio.Group>
          <div className="botoes">
            <Link to="/turmas/create/step-1">
              <Button className="button-second" style={{ width: 345 }}>
                Voltar
              </Button>
            </Link>
            <div onClick={() => handleSubmit()}>
              <Button className="button-primary" style={{ width: 345 }}>
                Próximo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Button
        onClick={() => setIsOpen(true)}
        className="button-fechar"
        icon={<CloseOutlined />}
      />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Você deseja cancelar a adição desta Nova turma?"
        subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
        textButton1="Não"
        textButton2="Sim, Cancelar"
        styleButton1="button-line"
        styleButton2="button-danger"
        link2="/turmas"
      />
    </div>
  );
};
