import {
  IUnidadePessoaCleanDTO,
  IUnidadePessoaDTO,
  IUnidadePessoaFullDTO,
} from '../models/happy-code-api.model';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useUnidadePessoaService = () => {
  const resourceURL = 'unidade-pessoa';

  return {
    ...baseFind<
      IUnidadePessoaCleanDTO,
      IUnidadePessoaDTO,
      IUnidadePessoaFullDTO
    >(resourceURL),
    ...baseFindList<
      IUnidadePessoaCleanDTO,
      IUnidadePessoaDTO,
      IUnidadePessoaFullDTO
    >(resourceURL),
    ...baseFindById<
      IUnidadePessoaCleanDTO,
      IUnidadePessoaDTO,
      IUnidadePessoaFullDTO
    >(resourceURL),
  };
};
