import * as Unicons from '@iconscout/react-unicons';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/sidebar/logo-grande.svg';
import LogoGrande from '../../assets/img/login/logo.svg';
import People from '../../assets/img/login/img-people.png';
import { useForm } from 'react-hook-form';
import { useNotificationService } from '../../services/notification.service';
import { useUsuarioService } from '../../services/usuario.service';
import { useGlobalContext } from '../../context/GlobalContext';

interface TInputs {
  email: string;
}

export const Senha: React.FunctionComponent = () => {
  const { register, handleSubmit } = useForm<TInputs>();
  const notification = useNotificationService();
  const userService = useUsuarioService();
  const navigate = useNavigate();
  const { setIsGlobalLoading } = useGlobalContext();

  const onRecuperaSenha = (data: TInputs) => {
    setIsGlobalLoading(true);
    userService
      .recuperaSenha(data.email)
      .then(() => {
        notification({
          message: 'Sucesso!',
          description: `Por favor, acesse o seu email ${data.email} e clique no link de recuperação.`,
          type: 'info',
        });
        navigate('');
      })
      .finally(() => setIsGlobalLoading(false));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onRecuperaSenha)}
        className="container-login"
        style={{ backgroundColor: 'var(--white-color' }}
      >
        <img src={Logo} alt={'logo'} className="logo-login" />
        <img src={LogoGrande} alt={'logo'} className="img-layout" />
        <div className="formulario-box">
          <div className="formulario">
            <div className="arrow">
              <Link to="/login">
                <Unicons.UilArrowLeft size="24" color="#6A7C96" />
              </Link>
              <span>Voltar</span>
            </div>
            <h3>Recuperação de senha</h3>
            <div className="input">
              <Unicons.UilEnvelopeAlt
                size="20"
                color="#6A7C96"
                className="iconForm"
              />
              <input
                {...register('email', { required: true })}
                type="text"
                placeholder="Email"
              />
            </div>
            <button
              type="submit"
              className="button-second"
              style={{ width: '100%', height: 56 }}
            >
              <span>Enviar</span>
            </button>
          </div>
        </div>
        <div className="container-blue"></div>
        <img src={People} alt="" className="img-people" />
      </form>
    </>
  );
};
