import {
  IAulaFullDTO,
  IFrequenciaDTO,
  IMatriculaFullDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAulaService } from '../../services/aula.service';
import { useFrequenciaService } from '../../services/frequencia.service';
import { useMatriculaService } from '../../services/matricula.service';
import { useNotificationService } from '../../services/notification.service';
import { useGlobalContext } from '../../context/GlobalContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function useLancamentoAula() {
  const { id } = useParams<any>();
  const [aula, setAula] = useState<IAulaFullDTO>();
  const [listMatricula, setListMatricula] = useState<IMatriculaFullDTO[]>();
  const [listFrequecia, setListFrequencia] = useState<IFrequenciaDTO[]>([]);
  const [isView, setIsView] = useState<boolean>(false);
  const { setIsGlobalLoading } = useGlobalContext();
  const aulaService = useAulaService();
  const matriculaService = useMatriculaService();
  const frequenciaService = useFrequenciaService();
  const notification = useNotificationService();

  const findAula = () => {
    const parseIdToNumber = parseInt(id);
    aulaService
      .findFullById({ id: parseIdToNumber })
      .then(({ data }) => setAula(data));
  };

  const findFrequencia = () => {
    const frequenciaPredicate = new Predicate();
    frequenciaPredicate.addOption('turma.id', aula.turma.id);
    frequenciaPredicate.addOption('dataPresenca', aula.data);

    return frequenciaService.findList(frequenciaPredicate).then(({ data }) => {
      const frequencias = data;

      if (frequencias.length !== 0) setIsView(true);

      listMatricula.forEach((matricula) => {
        frequencias.push({
          id: null,
          ativo: true,
          dataPresenca: aula.data,
          frequencia: true,
          reforco: false,
          matricula: matricula,
          turma: { ...aula.turma },
          frequenciaTipo: {
            id: null,
            codigo: '',
            descricao: '',
            ativo: true,
          },
          frequenciaTarefa: {
            id: null,
            codigo: '',
            descricao: '',
            ativo: true,
          },
        });
      });

      setListFrequencia(frequencias);
    });
  };

  const findMatricula = () => {
    const matriculaPredicate = new Predicate();
    matriculaPredicate.addOption('turma.id', aula.turma.id);

    return matriculaService
      .findFullList(matriculaPredicate)
      .then(({ data }) => {
        setListMatricula(data);
      });
  };

  useEffect(() => {
    if (!id) return;
    setIsGlobalLoading(true);
    Promise.all([findAula()]).finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!aula) return;

    setIsGlobalLoading(true);
    Promise.all([findMatricula()]).finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aula]);

  useEffect(() => {
    if (!listMatricula) return;
    setIsGlobalLoading(true);
    findFrequencia().finally(() => setIsGlobalLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMatricula]);

  const onChangeFrequencia = (
    presenca: string,
    matricula: IMatriculaFullDTO,
  ) => {
    if (listFrequecia && listFrequecia.length > 0)
      listFrequecia.find(
        (frequencia) => frequencia?.matricula?.id === matricula.id,
      ).frequenciaTipo.descricao = presenca;
  };

  const onChangeFrequenciaTarefa = (
    tarefa: string,
    matricula: IMatriculaFullDTO,
  ) => {
    if (listFrequecia && listFrequecia.length > 0)
      listFrequecia.find(
        (frequencia) => frequencia.matricula.id === matricula.id,
      ).frequenciaTarefa.descricao = tarefa;
  };

  const onChangeFrequenciaReforco = (
    reforco: boolean,
    matricula: IMatriculaFullDTO,
  ) => {
    if (listFrequecia && listFrequecia.length > 0)
      listFrequecia.find(
        (reforco) => reforco.matricula.id === matricula.id,
      ).reforco = reforco;
  };

  const registrarLancamento = () => {
    setIsGlobalLoading(true);
    if (isView) {
      frequenciaService
        .patchAll(listFrequecia)
        .then(() => {
          setIsView(true);
          setIsGlobalLoading(true);
          return findFrequencia().finally(() => setIsGlobalLoading(false));
        })
        .finally(() => {
          setIsGlobalLoading(false);
          notification({
            description: `Lançamento realizado com sucesso!`,
            type: 'success',
            message: 'Sucesso!',
          });
        });
    } else {
      frequenciaService
        .saveAll(listFrequecia)
        .then(() => {
          setIsView(true);
          setIsGlobalLoading(true);
          return findFrequencia().finally(() => {
            setIsGlobalLoading(false);
          });
        })
        .finally(() => {
          setIsGlobalLoading(false);
          notification({
            description: `Lançamento realizado com sucesso!`,
            type: 'success',
            message: 'Sucesso!',
          });
        });
    }
  };

  const getFrequenciaValue = (matricula: IMatriculaFullDTO): IFrequenciaDTO =>
    listFrequecia?.find?.(
      (frequencia) => frequencia.matricula.id === matricula.id,
    );

  const getFrequenciaValueTarefa = (
    matricula: IMatriculaFullDTO,
  ): IFrequenciaDTO =>
    listFrequecia?.find?.((tarefa) => tarefa.matricula.id === matricula.id);

  const getFrequenciaValueReforco = (
    matricula: IMatriculaFullDTO,
  ): IFrequenciaDTO =>
    listFrequecia?.find?.((reforco) => reforco.matricula.id === matricula.id);

  return {
    aula,
    listMatricula,
    isView,
    onChangeFrequencia,
    onChangeFrequenciaTarefa,
    onChangeFrequenciaReforco,
    registrarLancamento,
    getFrequenciaValue,
    getFrequenciaValueTarefa,
    getFrequenciaValueReforco,
  };
}
