import http from '../helpers/http-common';
import {
  IMatriculaProvaCleanDTO,
  IMatriculaProvaDTO,
  IMatriculaProvaFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';

export const useMatriculaProvaService = () => {
  const resourceURL = 'matricula-prova';

  const enviarNota = (
    body: IMatriculaProvaCleanDTO,
  ): Promise<IAxiosResponseCustom> => http.post(`${resourceURL}`, body);

  const patchNotas = (body): Promise<IAxiosResponseCustom> =>
    http.patch(`${resourceURL}`, body);

  return {
    ...baseFind<
      IMatriculaProvaCleanDTO,
      IMatriculaProvaDTO,
      IMatriculaProvaFullDTO
    >(resourceURL),
    ...baseFindById<
      IMatriculaProvaCleanDTO,
      IMatriculaProvaDTO,
      IMatriculaProvaFullDTO
    >(resourceURL),
    ...baseFindList<
      IMatriculaProvaCleanDTO,
      IMatriculaProvaDTO,
      IMatriculaProvaFullDTO
    >(resourceURL),
    ...baseCreate<
      IMatriculaProvaCleanDTO,
      IMatriculaProvaDTO,
      IMatriculaProvaFullDTO
    >(resourceURL),
    patchNotas,
    enviarNota,
  };
};
