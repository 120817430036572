import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar';
import '../../global.css';
import './turma-detalhe.css';
import AvaliacoesTable from './components/AvaliacoesTable';
import AlunoTable from './components/AlunoTable';
import AulasTable from './components/AulasTable';
import useTurmaDetalhe from './useTurmaDetalhe';
import BoxDataTurmaDetalhe from './components/BoxDataTurmaDetalhe';
import PorcentagemAulas from './components/PorcentagemAulas';

export const TurmasDetalhe: React.FunctionComponent = () => {
  const {
    listMatricula,
    listAula,
    onPageChange,
    onPageChangeMatricula,
    page,
    findMatricula,
  } = useTurmaDetalhe();

  return (
    <div className="container" style={{ overflow: 'hidden' }}>
      <Sidebar gestaoSalaAula />
      <div className="franquia" id="aluno-detalhe-row">
        <div
          className="franquia-row"
          style={{ marginTop: 70 }}
          id="row-aluno-box"
        >
          <div className="head-botoes" id="detalhe-turma">
            <Link to="/turmas">
              <Button className="button-second" icon={<ArrowLeftOutlined />}>
                Voltar
              </Button>
            </Link>
            <h5 style={{ marginLeft: 15 }}>Detalhes de turma</h5>
          </div>
          <div className="box-data-aluno">
            <BoxDataTurmaDetalhe />
            <PorcentagemAulas />
          </div>
          <div className="row-revisao">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Alunos" key="1">
                <AlunoTable
                  listMatricula={listMatricula}
                  onPageChange={onPageChangeMatricula}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Aulas" key="2">
                <AulasTable
                  listAula={listAula}
                  onPageChange={onPageChange}
                  page={page}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Avaliação" key="3">
                <AvaliacoesTable
                  listMatricula={listMatricula}
                  onPageChange={onPageChangeMatricula}
                  findMatricula={findMatricula}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
