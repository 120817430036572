import { IAulaCleanDTO } from '../../../models/happy-code-api.model';
import { isBeforeDate } from '../../../util/format';
import useTurmaDetalhe from '../useTurmaDetalhe';

export default function useQuantidadeAulaRealizadas() {
  const { turma } = useTurmaDetalhe();

  const quantidadeAulasRealizadas = () => {
    if (!turma) return 0;

    const { aulas } = turma;

    return (
      aulas?.filter(({ data }: IAulaCleanDTO) => isBeforeDate(data)).length || 0
    );
  };

  return quantidadeAulasRealizadas;
}
